@import "src/pages/components/mixins/mixins";

.wysiwyg {
    h1, h2, h3, h4, h5, h5 {
        margin: 24px 0 0;
        font-weight: 600;
        font-size: 36px;
        line-height: 120%;
        letter-spacing: -1px;
        font-family: 'Ubuntu', sans-serif;
        &:first-child {
            margin-top: 0;
        }
    }
    p {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        margin: 24px 0 0;
        a {
            margin-top: 0;
        }
        &:first-child {
            margin-top: 0;
        }
    }
    a {
        color: #0085FF;
        margin-top: 16px;
        &:hover {
            text-decoration: underline;
        }
        &:first-child {
            margin-top: 0;
        }
    }
    img {
        width: 100%;
        margin-top: 24px;
        border-radius: 8px;
        &:first-child {
            margin-top: 0;
        }
    }
}

@include media-breakpoint-xs {
    .wysiwyg {
        h1, h2, h3, h4, h5, h5 {
            margin: 16px 0 0;
            font-weight: 600;
            font-size: 20px;
            line-height: 120%;

            &:first-child {
                margin-top: 0;
            }
        }

        p {
            margin-top: 16px;

            &:first-child {
                margin-top: 0;
            }
        }
    }
}