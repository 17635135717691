@import "src/pages/components/mixins/mixins";

.footer {
    margin-top: 24px;
    background-color: #F6F6F6;
    padding: 24px 0;
}
.footer__inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}
.footer__logo {
    color: #939393;
    font-family: 'Nunito Sans', sans-serif;
}
.footer__copyright {
    color: #939393;
    width: 100%;
    margin-top: 8px;
}
.footer__nav-list {
    display: flex;
    align-items: center;
}
.footer__nav-item {
    margin-left: 16px;
    .button {
        color: #939393;
        &:hover {
            background-color: #e7e7e7;
        }
    }
    &:first-child {
        margin-left: 0;
    }
}

@include media-breakpoint-xs {
    .footer__inner {
        flex-direction: column;
    }
    .footer__nav {
        width: 100%;
    }
    .footer__nav-list {
        margin-top: 16px;
        flex-direction: column;
        width: 100%;
    }
    .footer__nav-item {
        width: 100%;
        margin-left: 0;
        .button {
            width: 100%;
        }
    }
    .footer__copyright {
        width: fit-content;
        margin-top: 16px;
    }
}