@import "src/pages/components/mixins/mixins";

.header {
}
.nav-bar__logo {
    color: #fff;
    font-family: 'Nunito Sans', sans-serif;
    h1 {
        color: #fff;
        font-family: 'Nunito Sans', sans-serif;
    }
}

.nav-bar {
    padding: 12px 0;
    position: relative;
    background-color: #0085FF;
    z-index: 19;
    .button {
        text-align: left;
    }
}
.nav-bar__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.nav-bar__inner-item {
    display: flex;
    align-items: center;
}
.nav-bar__list {
    display: flex;
    align-items: center;
    margin-left: 24px;
}
.nav-bar__list-item {
    margin-left: 16px;
    &.login {
        display: none;
    }
    &:first-child {
        margin-left: 0;
    }
}
.toggler {
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    margin-left: -8px;
    margin-right: 8px;
    cursor: pointer;
    display: none;
    svg {
        fill: #fff;
    }
}
.togger-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 15;
    display: none;
    cursor: pointer;
}

@include media-breakpoint-sm {
    .collapse-item {
        display: none;
    }
    .toggler {
        display: flex;
    }
    .nav-bar__list.nav-menu {
        .login {
            display: block;
        }
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #0085FF;
        width: 100%;
        margin-left: 0;
        padding: 16px;
        .nav-bar__list-item {
            margin-left: 0;
            margin-top: 4px;
            &:first-child {
                margin-top: 0;
            }
        }
        .button {
            width: 100%;
        }
    }
}

//region modal

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    align-items: center;
    justify-content: center;
    display: none;
}
.modal-background {
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
}
.modal-container {
    border-radius: 8px;
    padding: 16px;
    background-color: #fff;
    position: relative;
    z-index: 2;
    box-shadow: 0 10px 20px rgba(15, 15, 15, 0.15);
    max-width: 374px;
    width: calc(100% - 16px);
}
.modal-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.modal-title {
    padding: 8px 0 8px 8px;
}
.modal-close {
    margin-left: 16px;
    .button {
        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }
        &.icon svg {
            fill: #000;
        }
    }
}
.modal-form {
    margin-top: 16px;
}
@media (pointer: coarse) {
    .modal-close {
        .button {
            &:hover {
                background-color: transparent;
            }
        }
    }
}

//endregion