.font-label1 {
  font-weight: 700;
  font-size: 86px;
  line-height: 110%;
  letter-spacing: -3px;
}

.font-label2 {
  font-weight: 700;
  font-size: 64px;
  line-height: 120%;
  letter-spacing: -2px;
}

.font-label3 {
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: -1px;
}

.font-lead {
  font-weight: 400;
  font-size: 24px;
  line-height: 135%;
}

.font-h1 {
  font-weight: 700;
  font-size: 26px;
  line-height: 120%;
}

.font-h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
}

.font-h3 {
  font-weight: 700;
  font-size: 18px;
  line-height: 135%;
}

.font-subhead {
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
}

.font-body {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
}

.font-caption {
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
}

.font-subcaption {
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
}

.wysiwyg h1, .wysiwyg h2, .wysiwyg h3, .wysiwyg h4, .wysiwyg h5, .wysiwyg h5 {
  margin: 24px 0 0;
  font-weight: 600;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: -1px;
  font-family: "Ubuntu", sans-serif;
}
.wysiwyg h1:first-child, .wysiwyg h2:first-child, .wysiwyg h3:first-child, .wysiwyg h4:first-child, .wysiwyg h5:first-child, .wysiwyg h5:first-child {
  margin-top: 0;
}
.wysiwyg p {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  margin: 24px 0 0;
}
.wysiwyg p a {
  margin-top: 0;
}
.wysiwyg p:first-child {
  margin-top: 0;
}
.wysiwyg a {
  color: #0085FF;
  margin-top: 16px;
}
.wysiwyg a:hover {
  text-decoration: underline;
}
.wysiwyg a:first-child {
  margin-top: 0;
}
.wysiwyg img {
  width: 100%;
  margin-top: 24px;
  border-radius: 8px;
}
.wysiwyg img:first-child {
  margin-top: 0;
}

@media (max-width: 576px) {
  .wysiwyg h1, .wysiwyg h2, .wysiwyg h3, .wysiwyg h4, .wysiwyg h5, .wysiwyg h5 {
    margin: 16px 0 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
  }
  .wysiwyg h1:first-child, .wysiwyg h2:first-child, .wysiwyg h3:first-child, .wysiwyg h4:first-child, .wysiwyg h5:first-child, .wysiwyg h5:first-child {
    margin-top: 0;
  }
  .wysiwyg p {
    margin-top: 16px;
  }
  .wysiwyg p:first-child {
    margin-top: 0;
  }
}
html {
  box-sizing: border-box;
  height: 100%;
  overflow-x: hidden;
}
html .font-label, html .font-label1, html .font-label2, html .font-label3,
html .font-h1, html .font-h2, html .font-h3 {
  font-family: "Ubuntu", sans-serif;
}
html.stop-scroll {
  overflow: hidden;
}

img {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

*,
*::before,
*::after {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  display: inline-block;
  color: #000;
}

ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

main {
  flex: 1;
}

body {
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--background-tile);
  color: var(--elements-main);
}

.container {
  max-width: 1116px;
  padding: 0 16px;
  margin: 0 auto;
  width: 100%;
}

.nav-bar__logo {
  color: #fff;
  font-family: "Nunito Sans", sans-serif;
}
.nav-bar__logo h1 {
  color: #fff;
  font-family: "Nunito Sans", sans-serif;
}

.nav-bar {
  padding: 12px 0;
  position: relative;
  background-color: #0085FF;
  z-index: 19;
}
.nav-bar .button {
  text-align: left;
}

.nav-bar__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-bar__inner-item {
  display: flex;
  align-items: center;
}

.nav-bar__list {
  display: flex;
  align-items: center;
  margin-left: 24px;
}

.nav-bar__list-item {
  margin-left: 16px;
}
.nav-bar__list-item.login {
  display: none;
}
.nav-bar__list-item:first-child {
  margin-left: 0;
}

.toggler {
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  margin-left: -8px;
  margin-right: 8px;
  cursor: pointer;
  display: none;
}
.toggler svg {
  fill: #fff;
}

.togger-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 15;
  display: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .collapse-item {
    display: none;
  }

  .toggler {
    display: flex;
  }

  .nav-bar__list.nav-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #0085FF;
    width: 100%;
    margin-left: 0;
    padding: 16px;
  }
  .nav-bar__list.nav-menu .login {
    display: block;
  }
  .nav-bar__list.nav-menu .nav-bar__list-item {
    margin-left: 0;
    margin-top: 4px;
  }
  .nav-bar__list.nav-menu .nav-bar__list-item:first-child {
    margin-top: 0;
  }
  .nav-bar__list.nav-menu .button {
    width: 100%;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  align-items: center;
  justify-content: center;
  display: none;
}

.modal-background {
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
}

.modal-container {
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
  position: relative;
  z-index: 2;
  box-shadow: 0 10px 20px rgba(15, 15, 15, 0.15);
  max-width: 374px;
  width: calc(100% - 16px);
}

.modal-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-title {
  padding: 8px 0 8px 8px;
}

.modal-close {
  margin-left: 16px;
}
.modal-close .button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.modal-close .button.icon svg {
  fill: #000;
}

.modal-form {
  margin-top: 16px;
}

@media (pointer: coarse) {
  .modal-close .button:hover {
    background-color: transparent;
  }
}
.footer {
  margin-top: 24px;
  background-color: #F6F6F6;
  padding: 24px 0;
}

.footer__inner {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer__logo {
  color: #939393;
  font-family: "Nunito Sans", sans-serif;
}

.footer__copyright {
  color: #939393;
  width: 100%;
  margin-top: 8px;
}

.footer__nav-list {
  display: flex;
  align-items: center;
}

.footer__nav-item {
  margin-left: 16px;
}
.footer__nav-item .button {
  color: #939393;
}
.footer__nav-item .button:hover {
  background-color: #e7e7e7;
}
.footer__nav-item:first-child {
  margin-left: 0;
}

@media (max-width: 576px) {
  .footer__inner {
    flex-direction: column;
  }

  .footer__nav {
    width: 100%;
  }

  .footer__nav-list {
    margin-top: 16px;
    flex-direction: column;
    width: 100%;
  }

  .footer__nav-item {
    width: 100%;
    margin-left: 0;
  }
  .footer__nav-item .button {
    width: 100%;
  }

  .footer__copyright {
    width: fit-content;
    margin-top: 16px;
  }
}
.button {
  padding: 8px 16px;
  display: inline-block;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.15s, color 0.15s;
  text-align: center;
  outline: none;
  border: none;
}
.button:hover {
  background-color: rgba(255, 255, 255, 0.15);
}
.button.light {
  background-color: #fff;
  color: #0085FF;
}
.button.light:hover {
  background-color: transparent;
  color: #fff;
}
.button.primary {
  background-color: #0085FF;
  color: #fff;
}
.button.primary:hover {
  background-color: rgba(0, 133, 255, 0.8);
}
.button.secondary {
  background-color: rgba(165, 165, 165, 0.15);
  color: #000;
}
.button.secondary:hover {
  background-color: rgba(165, 165, 165, 0.25);
}
.button.icon {
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.button.icon svg {
  fill: #fff;
  flex-shrink: 0;
}
.button.large {
  padding: 13px 24px;
}

@media (pointer: coarse) {
  .button:hover {
    background-color: transparent !important;
  }
  .button.light:hover {
    background-color: #fff !important;
    color: #0085FF !important;
  }
  .button.primary:hover {
    background-color: #0085FF;
  }
  .button.secondary:hover {
    background-color: rgba(165, 165, 165, 0.15);
  }
}
.banner {
  margin-top: 24px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.banner:first-child {
  margin-top: 0;
}
.banner.mobile {
  display: none;
}

.big-banner {
  display: flex;
  justify-content: center;
}

.banner-lg {
  max-width: 300px;
}

.banner-md {
  max-width: 300px;
}

.banner-sm {
  max-width: 320px;
}

.banner-xs {
  max-width: 320px;
}

@media (max-width: 576px) {
  .banner.mobile {
    display: block;
  }

  .big-banner {
    display: none;
  }
}
.categories__wrapper {
  display: flex;
  margin-left: -8px;
  padding: 16px 0;
  border-bottom: 1px solid rgba(165, 165, 165, 0.25);
  overflow: auto;
}

.categories__item {
  margin-left: 8px;
  padding: 8px 16px;
  color: #000;
  transition: background-color 0.15s;
  cursor: pointer;
  border-radius: 8px;
}
.categories__item:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

.wide-mesh {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-top: -20px;
}

.wide-mesh__item {
  margin-left: 20px;
  margin-top: 20px;
  width: calc(25% - 20px);
}

@media (max-width: 992px) {
  .wide-mesh__item {
    width: calc(33.3333333333% - 20px);
  }
}
@media (max-width: 768px) {
  .wide-mesh__item {
    width: calc(50% - 20px);
  }
}
@media (max-width: 576px) {
  .wide-mesh {
    margin-left: 0;
  }

  .wide-mesh__item {
    width: 100%;
    margin-left: 0;
  }
}
.medium-mesh {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-top: -20px;
}

.medium-mesh__item {
  margin-top: 20px;
  margin-left: 20px;
  width: calc(33.3333333333% - 20px);
}

@media (max-width: 768px) {
  .medium-mesh__item {
    width: calc(50% - 20px);
  }
}
@media (max-width: 576px) {
  .medium-mesh {
    margin-left: 0;
  }

  .medium-mesh__item {
    width: 100%;
    margin-left: 0;
  }
}
.small-mesh {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-top: -20px;
}

.small-mesh__item {
  margin-top: 20px;
  margin-left: 20px;
  width: calc(50% - 20px);
}

@media (max-width: 576px) {
  .small-mesh {
    margin-left: 0;
  }

  .small-mesh__item {
    width: 100%;
    margin-left: 0;
  }
}
.mono-mesh__item {
  margin-top: 20px;
}
.mono-mesh__item:first-child {
  margin-top: 0;
}

.article-card {
  background-color: #fff;
  border: 1px solid rgba(165, 165, 165, 0.25);
  border-radius: 8px;
  overflow: hidden;
  display: block;
  transition: box-shadow 0.15s;
  position: relative;
}
.article-card:hover {
  box-shadow: 0 10px 20px rgba(15, 15, 15, 0.15);
}
.article-card.small {
  border: none;
  border-radius: 0;
  display: flex;
}
.article-card.small:hover {
  box-shadow: none;
}
.article-card.small:hover .article-card__title {
  text-decoration: underline;
}
.article-card.small .article-card__image {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  padding-bottom: 0;
  overflow: hidden;
  flex-shrink: 0;
}
.article-card.small .article-card__wrapper {
  margin-left: 16px;
  padding: 0;
}
.article-card.small .article-card__title {
  margin-top: 4px;
}
.article-card.small .article-card__date {
  position: static;
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  color: #000000;
}

.article-card__image {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 48.85%;
  z-index: 1;
}
.article-card__image img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

.article-card__box {
  display: block;
}
.article-card__box:hover .article-card__title {
  text-decoration: underline;
}

.article-card__wrapper {
  padding: 12px;
}

.article-card__title {
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.article-card__description {
  margin-top: 18px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.article-card__author {
  padding: 8px 12px 12px;
  display: flex;
  align-items: center;
}
.article-card__author:hover .article-card__author-name {
  text-decoration: underline;
}

.article-card__author-photo {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
}
.article-card__author-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.article-card__author-name {
  margin-left: 8px;
  color: #7F7F7F;
}

.sidebar {
  border: 1px solid rgba(165, 165, 165, 0.25);
  border-radius: 8px;
  padding: 16px;
  margin-top: 24px;
  max-height: 706px;
  display: flex;
  flex-direction: column;
}
.sidebar:first-child {
  margin-top: 0;
}

.sidebar__inner {
  margin-top: 24px;
  flex: 1;
  overflow: auto;
}

.sidebar__item {
  margin-top: 16px;
}
.sidebar__item:first-child {
  margin-top: 0;
}

.grid-2-1 {
  display: flex;
  margin-left: -20px;
  flex-wrap: wrap;
}
.grid-2-1 .grid-big {
  width: calc(66.6666666667% - 20px);
  margin-left: 20px;
}
.grid-2-1 .grid-small {
  width: calc(33.3333333333% - 20px);
  margin-left: 20px;
}

.grid-1-1 {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
}
.grid-1-1 .grid-item {
  width: calc(50% - 20px);
  margin-left: 20px;
}

@media (max-width: 992px) {
  .grid-2-1 {
    margin-left: 0;
  }
  .grid-2-1 .grid-big {
    width: 100%;
    margin-left: 0;
  }
  .grid-2-1 .grid-small {
    width: 100%;
    margin-left: 0;
    margin-top: 24px;
  }
}
@media (max-width: 768px) {
  .grid-2-1 {
    margin-left: 0;
  }
  .grid-2-1 .grid-big {
    width: 100%;
    margin-left: 0;
  }
  .grid-2-1 .grid-small {
    width: 100%;
    margin-left: 0;
    margin-top: 24px;
  }

  .grid-1-1 {
    margin-left: 0;
  }
  .grid-1-1 .grid-item {
    margin-top: 24px;
    width: 100%;
    margin-left: 0;
  }
  .grid-1-1 .grid-item:first-child {
    margin-top: 0;
  }
}
.swiper {
  padding: 0 20px 32px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -32px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.swiper-wrapper {
  justify-content: space-between !important;
  display: flex;
}

.swiper-slide {
  width: calc(25% - 20px);
  flex-shrink: 0;
}

.swiper-box {
  position: relative;
}
.swiper-box .button.icon {
  background-color: rgba(241, 242, 237, 0.4);
  transition: background-color 0.15s;
}
.swiper-box .button.icon:hover {
  background-color: #F1F2ED;
}
.swiper-box .button.icon svg {
  fill: #000;
}

.swiper-btn-prev {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 0;
  z-index: 5;
  height: 100%;
  display: flex;
  align-items: center;
}

.swiper-btn-next {
  position: absolute;
  height: 100%;
  top: 50%;
  transform: translate(50%, -50%);
  right: 0;
  z-index: 5;
  display: flex;
  align-items: center;
}

@media (max-width: 992px) {
  .swiper-slide {
    width: calc(33.3333333333% - 20px);
  }
}
@media (max-width: 768px) {
  swiper-slide {
    width: calc(50% - 20px);
  }
}
@media (max-width: 576px) {
  .swiper-slide {
    width: 100%;
  }
}
.most-recent {
  margin-top: 32px;
}

.most-recent__title {
  padding: 8px 0;
}

.most-recent__swiper {
  margin-top: 24px;
}

.read-more {
  margin-top: 40px;
}

.read-more__title {
  padding: 8px 0;
}

.read-more__swiper {
  margin-top: 16px;
}

@media (max-width: 576px) {
  .read-more {
    margin-top: 24px;
  }
}
.latest {
  margin-top: 40px;
}
.latest .grid-2-1 {
  padding-top: 20px;
}

.latest-title {
  padding: 8px 0;
}

.articles-box {
  margin-top: 24px;
}
.articles-box .grid-2-1 {
  margin-top: 24px;
}

.articles-box__three {
  margin-top: 24px;
}

.hot-articles {
  margin-top: 24px;
}

.hot-articles__title {
  padding: 8px 0;
  text-align: center;
}

.hot-articles__wrapper {
  margin-top: 24px;
}

.about-wrapper, .privacy-wrapper, .contacts-wrapper {
  margin-top: 100px;
}

@media (max-width: 576px) {
  .about-wrapper, .privacy-wrapper, .contacts-wrapper {
    margin-top: 32px;
  }
}
.input {
  padding: 12px 16px;
  border: 1px solid #BFBFBF;
  border-radius: 8px;
  margin-top: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  outline: none;
  width: 100%;
}
.input:focus {
  border-color: #0085FF;
}
.input::placeholder {
  opacity: 0.5;
}
.input:first-child {
  margin-top: 0;
}

textarea {
  resize: vertical;
  min-height: 104px;
}

form {
  margin-top: 32px;
  max-width: 712px;
}
form:first-child {
  margin-top: 0;
}
form .button {
  margin-top: 16px;
  width: 100%;
}

.article {
  margin-top: 24px;
}

.article-author {
  display: inline-flex;
  align-items: center;
  margin-top: 0;
}
.article-author:hover .article-author__name {
  text-decoration: underline;
}

.article-author__photo {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}
.article-author__photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.article-author__name {
  color: #7F7F7F;
  margin-left: 8px;
}

.article-tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 32px;
  margin-left: -8px;
  margin-top: -8px;
}

.article-tags__item {
  margin-left: 8px;
  margin-top: 8px;
}

@media (max-width: 576px) {
  .article-tags {
    padding-top: 24px;
  }
}
.tags-sidebar .sidebar__inner {
  padding-top: 24px;
  margin-top: -8px;
  margin-left: -8px;
  flex-wrap: wrap;
  display: flex;
  height: 2000px;
}
.tags-sidebar .sidebar__item {
  width: fit-content;
  margin-left: 8px;
  margin-top: 8px;
}

.article-comments__title {
  padding: 8px 0;
}

.article-comments {
  margin-top: 32px;
}

.article-comments__box {
  margin-top: 24px;
}

.article-comments__item {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid rgba(165, 165, 165, 0.25);
}
.article-comments__item:first-child {
  margin-top: 0;
  padding-top: 0;
  border-top: none;
}

.article-comments__author {
  display: flex;
  align-items: center;
}

.article-comments__author-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}
.article-comments__author-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.article-comments__author-info {
  margin-left: 16px;
}

.article-comments__author-date {
  color: #7F7F7F;
}

.article-comments__author-name {
  margin-top: 4px;
}

.article-comments__text {
  margin-top: 8px;
}

.author-page__name {
  padding: 8px 0;
  margin-bottom: 24px;
}

.team {
  margin-top: 24px;
  display: block;
}

.team-wrapper {
  margin-top: 32px;
}

.team-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-item__photo {
  width: 128px;
  height: 128px;
  border-radius: 50%;
  overflow: hidden;
}
.team-item__photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-item__name {
  margin-top: 24px;
}

.team-item__position {
  margin-top: 8px;
  color: #7F7F7F;
}

.team-item__about {
  margin-top: 8px;
  text-align: center;
}

.authors {
  margin-top: 40px;
}

.authors-item {
  margin-top: 32px;
}
.authors-item:first-child {
  margin-top: 0;
}

.authors-wrapper {
  margin-top: 32px;
}

.authors-item__box {
  display: flex;
}

.authors-item__photo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}
.authors-item__photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.authors-item__info {
  margin-left: 24px;
}

.authors-item__about {
  margin-top: 8px;
}

.authors-item__button {
  margin-top: 8px;
}
.authors-item__button .button {
  width: 100%;
}

@media (max-width: 576px) {
  .authors {
    margin-top: 24px;
  }

  .authors-item {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid rgba(165, 165, 165, 0.25);
  }
  .authors-item:first-child {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }

  .authors-item__box {
    flex-wrap: wrap;
    justify-content: center;
  }

  .authors-item__info {
    margin-left: 0;
    width: 100%;
    margin-top: 16px;
  }

  .authors-item__name {
    text-align: center;
  }
}