.font-label1 {
    font-weight: 700;
    font-size: 86px;
    line-height: 110%;
    letter-spacing: -3px;
}

.font-label2 {
    font-weight: 700;
    font-size: 64px;
    line-height: 120%;
    letter-spacing: -2px;
}

.font-label3 {
    font-weight: 700;
    font-size: 36px;
    line-height: 120%;
    letter-spacing: -1px;
}

.font-lead {
    font-weight: 400;
    font-size: 24px;
    line-height: 135%;
}


.font-h1 {
    font-weight: 700;
    font-size: 26px;
    line-height: 120%;
}

.font-h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
}

.font-h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 135%;
}

.font-subhead {
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
}

.font-body {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
}

.font-caption {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
}

.font-subcaption {
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
}
