@import "src/pages/components/mixins/mixins";
@import "src/pages/templates/fonts/font-styles";
@import "wysiwyg";

html {
    box-sizing: border-box;
    height: 100%;
    overflow-x: hidden;

    .font-label, .font-label1, .font-label2, .font-label3,
    .font-h1, .font-h2, .font-h3 {
        font-family: 'Ubuntu', sans-serif;
    }
    &.stop-scroll {
        overflow: hidden;
    }
}

img {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

*,
*::before,
*::after {
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    display: inline-block;
    color: #000;
}


ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
}

main {
    flex: 1;
}


body {
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--background-tile);
    color: var(--elements-main);
}

.container {
    max-width: 1116px;
    padding: 0 16px;
    margin: 0 auto;
    width: 100%;
}

@import "src/pages/templates/template2/assets/css/header";
@import "src/pages/templates/template2/assets/css/footer";

.button {
    padding: 8px 16px;
    display: inline-block;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.15s, color 0.15s;
    text-align: center;
    outline: none;
    border: none;
    &:hover {
        background-color: rgba(255, 255, 255, 0.15);
    }
    &.light {
        background-color: #fff;
        color: #0085FF;
        &:hover {
            background-color: transparent;
            color: #fff;
        }
    }
    &.primary {
        background-color: #0085FF;
        color: #fff;
        &:hover {
            background-color: rgba(0, 133, 255, 0.8);
        }
    }
    &.secondary {
        background-color: rgba(165, 165, 165, 0.15);
        color: #000;
        &:hover {
            background-color: rgba(165, 165, 165, 0.25);
        }
    }
    &.icon {
        height: 35px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        svg {
            fill: #fff;
            flex-shrink: 0;
        }
    }
    &.large {
        padding: 13px 24px;
    }
}

@media (pointer: coarse) {
    .button {
        &:hover {
            background-color: transparent !important;
        }
        &.light {
            &:hover {
                background-color: #fff !important;
                color: #0085FF !important;
            }
        }
        &.primary {
            &:hover {
                background-color: #0085FF;
            }
        }
        &.secondary {
            &:hover {
                background-color: rgba(165, 165, 165, 0.15);
            }
        }
    }
}

//region banners

.banner {
    //background-color: yellow;
    //height: 100px;
    margin-top: 24px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    &:first-child {
        margin-top: 0;
    }
    &.mobile {
        display: none;
    }
}
.big-banner {
    display: flex;
    justify-content: center;
}
.banner-lg {
    max-width: 300px;
}
.banner-md {
    max-width: 300px;
}
.banner-sm {
    max-width: 320px;
}
.banner-xs {
    max-width: 320px;
}
@include media-breakpoint-xs {
    .banner.mobile {
        display: block;
    }
    .big-banner {
        display: none;
    }
}

//endregion

//region categories

.categories__wrapper {
    display: flex;
    margin-left: -8px;
    padding: 16px 0;
    border-bottom: 1px solid rgba(165, 165, 165, 0.25);
    overflow: auto;
}
.categories__item {
    margin-left: 8px;
    padding: 8px 16px;
    color: #000;
    transition: background-color 0.15s;
    cursor: pointer;
    border-radius: 8px;
    &:hover {
        background-color: rgba(0, 0, 0, 0.15);
    }
}

//endregion

//region inder

//region wide-mesh

.wide-mesh {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-top: -20px;
}
.wide-mesh__item {
    margin-left: 20px;
    margin-top: 20px;
    width: calc(25% - 20px);
}

@include media-breakpoint-md {
    .wide-mesh__item {
        width: calc(100% / 3 - 20px);
    }
}
@include media-breakpoint-sm {
    .wide-mesh__item {
        width: calc(50% - 20px);
    }
}
@include media-breakpoint-xs {
    .wide-mesh {
        margin-left: 0;
    }
    .wide-mesh__item {
        width: 100%;
        margin-left: 0;
    }
}

//endregion

//region medium mesh

.medium-mesh {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-top: -20px;
}
.medium-mesh__item {
    margin-top: 20px;
    margin-left: 20px;
    width: calc(100% / 3 - 20px);
}
@include media-breakpoint-sm {
    .medium-mesh__item {
        width: calc(50% - 20px);
    }
}
@include media-breakpoint-xs {
    .medium-mesh {
        margin-left: 0;
    }
    .medium-mesh__item {
        width: 100%;
        margin-left: 0;
    }
}


//endregion

//region small mesh

.small-mesh {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-top: -20px;
}
.small-mesh__item {
    margin-top: 20px;
    margin-left: 20px;
    width: calc(50% - 20px);
}

@include media-breakpoint-xs {
    .small-mesh {
        margin-left: 0;
    }
    .small-mesh__item {
        width: 100%;
        margin-left: 0;
    }
}

//endregion

//region mono mesh

.mono-mesh__item {
    margin-top: 20px;
    &:first-child {
        margin-top: 0;
    }
}

//endregion

//region article card

.article-card {
    background-color: #fff;
    border: 1px solid rgba(165, 165, 165, 0.25);
    border-radius: 8px;
    overflow: hidden;
    display: block;
    transition: box-shadow 0.15s;
    position: relative;
    &:hover {
        box-shadow: 0 10px 20px rgba(15, 15, 15, 0.15);
    }
    &.small {
        border: none;
        border-radius: 0;
        display: flex;
        &:hover {
            .article-card__title {
                text-decoration: underline;
            }
            box-shadow: none;
        }
        .article-card__image {
            width: 60px;
            height: 60px;
            border-radius: 8px;
            padding-bottom: 0;
            overflow: hidden;
            flex-shrink: 0;
        }
        .article-card__wrapper {
            margin-left: 16px;
            padding: 0;
        }
        .article-card__title {
            margin-top: 4px;
        }
        .article-card__date {
            position: static;
            background-color: transparent;
            padding: 0;
            border-radius: 0;
            color: #000000;
        }
    }
}
//.article-card__date {
//    position: absolute;
//    top: 8px;
//    left: 8px;
//    padding: 4px 8px;
//    background: rgba(0, 0, 0, 0.4);
//    border-radius: 24px;
//    color: #fff;
//    z-index: 2;
//}
.article-card__image {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 48.85%;
    z-index: 1;
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
    }
}
.article-card__box {
    display: block;
    &:hover {
        .article-card__title {
            text-decoration: underline;
        }
    }
}
.article-card__wrapper {
    padding: 12px;
}
.article-card__title {
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;

}
.article-card__description {
    margin-top: 18px;
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}
.article-card__author {
    padding: 8px 12px 12px;
    display: flex;
    align-items: center;
    &:hover {
        .article-card__author-name {
            text-decoration: underline;
        }
    }
}
.article-card__author-photo {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.article-card__author-name {
    margin-left: 8px;
    color: #7F7F7F;
}

//endregion

//region sidebar

.sidebar {
    border: 1px solid rgba(165, 165, 165, 0.25);
    border-radius: 8px;
    padding: 16px;
    margin-top: 24px;
    max-height: 706px;
    display: flex;
    flex-direction: column;
    &:first-child {
        margin-top: 0;
    }
}
.sidebar__inner {
    margin-top: 24px;
    flex: 1;
    overflow: auto;
}
.sidebar__item {
    margin-top: 16px;
    &:first-child {
        margin-top: 0;
    }
}

//endregion

//region grid

.grid-2-1 {
    display: flex;
    margin-left: -20px;
    flex-wrap: wrap;
    .grid-big {
        width: calc(100% / 3 * 2 - 20px);
        margin-left: 20px;
    }
    .grid-small {
        width: calc(100% / 3  - 20px);
        margin-left: 20px;
    }
}
.grid-1-1 {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    .grid-item {
        width: calc(50% - 20px);
        margin-left: 20px;
    }
}
@include media-breakpoint-md {
    .grid-2-1 {
        margin-left: 0;
        .grid-big {
            width: 100%;
            margin-left: 0;
        }
        .grid-small {
            width: 100%;
            margin-left: 0;
            margin-top: 24px;
        }
    }
}
@include media-breakpoint-sm {
    .grid-2-1 {
        margin-left: 0;
        .grid-big {
            width: 100%;
            margin-left: 0;
        }
        .grid-small {
            width: 100%;
            margin-left: 0;
            margin-top: 24px;
        }
    }
    .grid-1-1 {
        margin-left: 0;
        .grid-item {
            margin-top: 24px;
            width: 100%;
            margin-left: 0;
            &:first-child {
                margin-top: 0;
            }
        }
    }

}

//endregion

//region swiper

.swiper {
    padding: 0 20px 32px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -32px;
    display: flex;
    justify-content: space-between;
    position: relative;
}
.swiper-wrapper {
    justify-content: space-between !important;
    display: flex;
}
.swiper-slide {
    width: calc(25% - 20px);
    flex-shrink: 0;
}
.swiper-box {
    position: relative;
    .button.icon {
        background-color: rgba(241, 242, 237, 0.4);
        transition: background-color 0.15s;
        &:hover {
            background-color: #F1F2ED;
        }
        
        svg {
            fill: #000;
        }
    }
}
.swiper-btn-prev {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 0;
    z-index: 5;
    height: 100%;
    display: flex;
    align-items: center;
}
.swiper-btn-next {
    position: absolute;
    height: 100%;
    top: 50%;
    transform: translate(50%, -50%);
    right: 0;
    z-index: 5;
    display: flex;
    align-items: center;
}
@include media-breakpoint-md {
    .swiper-slide {
        width: calc(100% / 3 - 20px);
    }
}
@include media-breakpoint-sm {
    swiper-slide {
        width: calc(50% - 20px);
    }
}
@include media-breakpoint-xs {
    .swiper-slide {
        width: 100%;
    }
    .swiper-box {
        .button.icon {
            //display: none;
        }
    }
}


//endregion

//region most recent

.most-recent {
    margin-top: 32px;
}

.most-recent__title {
    padding: 8px 0;
}
.most-recent__swiper {
    margin-top: 24px;
}

//endregion

//region read more

.read-more {
    margin-top: 40px;
}

.read-more__title {
    padding: 8px 0;
}
.read-more__swiper {
    margin-top: 16px;
}
@include media-breakpoint-xs {
    .read-more {
        margin-top: 24px;
    }
}

//endregion

.latest {
    margin-top: 40px;
    .grid-2-1 {
        padding-top: 20px;
    }
}
.latest-title {
    padding: 8px 0;
}

.articles-box {
    margin-top: 24px;
    .grid-2-1 {
        margin-top: 24px;
    }
}
.articles-box__three {
    margin-top: 24px;
}

.hot-articles {
    margin-top: 24px;
}
.hot-articles__title {
    padding: 8px 0;
    text-align: center;
}
.hot-articles__wrapper {
    margin-top: 24px;
}


//endregion

//region about & privacy & contacts

.about-wrapper, .privacy-wrapper, .contacts-wrapper {
    margin-top: 100px;
}

@include media-breakpoint-xs {
    .about-wrapper, .privacy-wrapper, .contacts-wrapper {
        margin-top: 32px;
    }
}

//endregion

//region form

.input {
    padding: 12px 16px;
    border: 1px solid #BFBFBF;
    border-radius: 8px;
    margin-top: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    outline: none;
    width: 100%;
    &:focus {
        border-color: #0085FF;
    }
    &::placeholder {
        opacity: 0.5;
    }
    &:first-child {
        margin-top: 0;
    }
}
textarea {
    resize: vertical;
    min-height: 104px;
}

form {
    margin-top: 32px;
    max-width: 712px;
    &:first-child {
        margin-top: 0;
    }
    .button {
        margin-top: 16px;
        width: 100%;
    }
}

//endregion

//region article

.article {
    margin-top: 24px;
}
.article-author {
    display: inline-flex;
    align-items: center;
    margin-top: 0;
    &:hover .article-author__name {
        text-decoration: underline;
    }
}
.article-author__photo {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.article-author__name {
    color: #7F7F7F;
    margin-left: 8px;
}

//endregion

//region article tags

.article-tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 32px;
    margin-left: -8px;
    margin-top: -8px;
}
.article-tags__item {
    margin-left: 8px;
    margin-top: 8px;
}

@include media-breakpoint-xs {
    .article-tags {
        padding-top: 24px;
    }
}

.tags-sidebar {
    .sidebar__inner {
        padding-top: 24px;
        margin-top: -8px;
        margin-left: -8px;
        flex-wrap: wrap;
        display: flex;
        height: 2000px;
    }
    .sidebar__item {
        width: fit-content;
        margin-left: 8px;
        margin-top: 8px;
    }
}

//endregion

//region comments

.article-comments__title {
    padding: 8px 0;
}
.article-comments {
    margin-top: 32px;
}
.article-comments__box {
    margin-top: 24px;
}
.article-comments__item {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid rgba(165, 165, 165, 0.25);
    &:first-child {
        margin-top: 0;
        padding-top: 0;
        border-top: none;
    }
}
.article-comments__author {
    display: flex;
    align-items: center;
}
.article-comments__author-photo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.article-comments__author-info {
    margin-left: 16px;
}
.article-comments__author-date {
    color: #7F7F7F;
}
.article-comments__author-name {
    margin-top: 4px;
}
.article-comments__text {
    margin-top: 8px;
}

//endregion

.author-page__name {
    padding: 8px 0;
    margin-bottom: 24px;
}

//region team

.team {
    margin-top: 24px;
    display: block;
}
.team-wrapper {
    margin-top: 32px;
}
.team-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.team-item__photo {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.team-item__name {
    margin-top: 24px;
}
.team-item__position {
    margin-top: 8px;
    color: #7F7F7F;
}
.team-item__about {
    margin-top: 8px;
    text-align: center;
}

.authors {
    margin-top: 40px;
}
.authors-item {
    margin-top: 32px;
    &:first-child {
        margin-top: 0;
    }
}

.authors-wrapper {
    margin-top: 32px;
}
.authors-item__box {
    display: flex;
}
.authors-item__photo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.authors-item__info {
    margin-left: 24px;
}
.authors-item__about {
    margin-top: 8px;
}
.authors-item__button {
    margin-top: 8px;
    .button {
        width: 100%;
    }
}

@include media-breakpoint-xs {
    .authors {
        margin-top: 24px;
    }
    .authors-item {
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid rgba(165, 165, 165, 0.25);
        &:first-child {
            margin-top: 0;
            padding-top: 0;
            border-top: none;
        }
    }
    .authors-item__box {
        flex-wrap: wrap;
        justify-content: center;
    }
    .authors-item__info {
        margin-left: 0;
        width: 100%;
        margin-top: 16px;
    }
    .authors-item__name {
        text-align: center;
    }
}

//endregion